import { LayoutBase } from '../../components/LayoutBase';

import useAuth from '../../hooks/useAuth';
import { ReportsList } from './components/ReportsList';
import { ReportsListNetwork } from './components/ReportsListNetwork';

export function Reports() {
  const { company } = useAuth();

  return (
    <LayoutBase title="Reports">
      {company && (
        <>{company?.has_network ? <ReportsListNetwork /> : <ReportsList />}</>
      )}
    </LayoutBase>
  );
}
