import { Select, Toast } from 'oialbert-ui';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import { AxiosError } from 'axios';
import { getAllCompanies } from '../../../services/companies';
import { Options } from '../../../types/options';
import { ReportsList } from './ReportsList';

export function ReportsListNetwork() {
  const { company } = useAuth();
  const { register, watch } = useForm<{ company: string }>({
    mode: 'onChange',
  });
  const [companies, setCompanies] = useState<Options[]>([]);
  const [page] = useState<number>(1);
  const [watchCompany] = watch(['company']);

  const tryGetAllCompaniesBelongingToBranch = useCallback(
    async (companyId: string) => {
      const response = await getAllCompanies(
        {
          limit: 100,
          page: page,
        },
        companyId
      );
      const options = response.companies.data.map((company: any) => {
        return {
          label: company.name,
          value: company.id,
        };
      });
      setCompanies(options);
    },
    [page]
  );
  const getAllCompaniesBelongingToBranch = useCallback(
    async (companyId: string) => {
      try {
        await tryGetAllCompaniesBelongingToBranch(companyId);
      } catch (err) {
        const error = err as AxiosError;
        if (error.response?.data?.message) {
          const { message } = error.response.data;
          Toast.crema(message);
        } else {
          Toast.crema('ocorreu um erro ao carregar empresas');
        }
      }
    },
    [tryGetAllCompaniesBelongingToBranch]
  );
  useEffect(() => {
    (async () => {
      if (company?.id) {
        await getAllCompaniesBelongingToBranch(company.id);
      }
    })();
  }, [company?.id, getAllCompaniesBelongingToBranch]);

  return (
    <>
      <form className="w-full flex flex-col mt-5 ">
        <h1 className="text-xl font-semibold">
          selecione uma empresa para continuar
        </h1>
        <section className="flex flex-col mt-3 mb-4 space-y-4">
          <Select
            label={''}
            options={companies}
            placeholder="Selecione uma empresa"
            {...register('company')}
          />
        </section>
      </form>
      {watchCompany && <ReportsList companySelectedId={watchCompany} />}
    </>
  );
}
